"use client";

import { useNav } from "@/providers/Nav";
import { useEffect } from "react";
export default function Announcement({
  showAnnouncement
}: {
  showAnnouncement: boolean;
}) {
  const {
    setShowAnnouncement
  } = useNav();
  useEffect(() => {
    setShowAnnouncement(showAnnouncement);
  }, [showAnnouncement]);
  return null;
}